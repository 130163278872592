import { match } from 'react-router-dom';

import { ROLE_MENUITEMS } from 'constants/permissions';
import { PARTNER_ROLES, ROLES } from 'constants/roles';
import UserPermissionsStore from 'stores/UserPermissionsStore';

export const canActivateOrigins = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.getSideBarAccess(
    ROLE_MENUITEMS.SUPER_ADMIN,
    PARTNER_ROLES.ADMIN,
    PARTNER_ROLES.WRITE,
    PARTNER_ROLES.READ
  ) ||
  userPermissionsStore.hasConfigAdminInOrigin ||
  userPermissionsStore.isSuperAdmin;

export const canAddOrigin = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.canEditCurrentPartner;

export const canAddCareUnit = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.canEditCurrentPartner;

export const canViewAccountDeletion = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.canViewAccountDeletion;

export const canActivateCareUnits = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.getSideBarAccess(
    ROLE_MENUITEMS.SUPER_ADMIN,
    PARTNER_ROLES.ADMIN,
    PARTNER_ROLES.WRITE,
    PARTNER_ROLES.READ
  ) ||
  userPermissionsStore.hasConfigAdminInCareUnit ||
  userPermissionsStore.isSuperAdmin;

export const canActivateSpecificCareUnits =
  (userPermissionsStore: UserPermissionsStore) =>
  ({ params }: match) => {
    // todo: pick careUnitId from params
    console.log('params:::', params);
    return (
      userPermissionsStore.getSideBarAccess(
        ROLE_MENUITEMS.SUPER_ADMIN,
        PARTNER_ROLES.ADMIN,
        PARTNER_ROLES.WRITE,
        PARTNER_ROLES.READ
      ) ||
      userPermissionsStore.hasConfigAdminInCareUnit ||
      userPermissionsStore.isSuperAdmin
    );
  };

export const canActivateContent24 = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.getSideBarAccess(
    ROLE_MENUITEMS.MEDICAL_CONTENT_VIEWER,
    ROLE_MENUITEMS.MEDICAL_CONTENT_EDITOR,
    ROLE_MENUITEMS.SUPER_ADMIN
  ) || userPermissionsStore.isSuperAdmin;

export const canActivatePractitioners = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.getSideBarAccess(
    ROLE_MENUITEMS.CLINIC_USER_ADMIN,
    ROLE_MENUITEMS.CONFIG_ADMIN,
    ROLE_MENUITEMS.SUPER_ADMIN
  ) ||
  userPermissionsStore.hasRole(ROLES.ADMIN) ||
  userPermissionsStore.isSuperAdmin;

export const canViewRules = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.getSideBarAccess(
    ROLE_MENUITEMS.BUSINESS_RULES_VIEWER,
    ROLE_MENUITEMS.BUSINESS_RULES_EDITOR,
    ROLE_MENUITEMS.SUPER_ADMIN
  );

export const canViewCarePathways = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.getSideBarAccess(
    ROLE_MENUITEMS.PATHWAY_EDITOR,
    ROLE_MENUITEMS.PATHWAY_VIEWER,
    ROLE_MENUITEMS.SUPER_ADMIN
  );

export const isSuperAdmin = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.isSuperAdmin;

// TODO AX-47065 Implement role access for opening hours
export const canActivateCareProviders = () => true;
export const canEditCareProviders = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.canEditCurrentPartner;
