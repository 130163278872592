import { PlusOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { FieldArray, Formik } from 'formik';
import { Form } from 'formik-antd';
import React, { useCallback, useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { FormControlButtons } from './FormControlButtons';
import { InheritanceStateInfo } from './InheritanceStateInfo';
import style from './OpeningHours.module.css';
import { RowSpecialDay } from './RowSpecialDay';
import BoxContent from '../../../../components/BoxContent';
import { useBoolean } from '../../../../hooks/useBoolean';
import { OpeningHoursContext } from '../openingHours.context';
import { useOpeningHoursOnSubmit, useOpeningHoursQuery } from '../openingHours.hooks';
import { MODE, OnSubmitType, OpeningHoursSpecial } from '../openingHours.types';
import { groupSpecialHours } from '../openingHours.utils';

export const FormSpecial = () => {
  const { id, mode } = useContext(OpeningHoursContext);
  const { data } = useOpeningHoursQuery(mode, id);
  const onSubmit = useOpeningHoursOnSubmit(mode, id);

  const {
    value: isEditingEnabled,
    setTrue: setIsEditingTrue,
    setFalse: setIsEditingFalse,
    setValue: setIsEditing,
  } = useBoolean(false);

  const initialValues = useMemo(() => {
    const values =
      isEditingEnabled && data!.candidate?.detailedSpecialHourPeriodsWrapper
        ? data!.candidate.detailedSpecialHourPeriodsWrapper
        : data!.release.detailedSpecialHourPeriodsWrapper;
    const specialHoursInheritedByParent = isEditingEnabled
      ? false
      : values.specialHoursInheritedByParent;
    return {
      ...values,
      specialHoursInheritedByParent,
    };
  }, [isEditingEnabled, data]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        detailedSpecialHourPeriods: Yup.array().when('specialHoursInheritedByParent', {
          is: false,
          then: Yup.array().of(
            Yup.object().shape({
              closedAllDay: Yup.boolean().required(),
              startDate: Yup.string().required(),
              endDate: Yup.string().required(),
              openTime: Yup.string().nullable().when('closedAllDay', {
                is: false,
                then: Yup.string().required(),
              }),
              closeTime: Yup.string().nullable().when('closedAllDay', {
                is: false,
                then: Yup.string().required(),
              }),
            })
          ),
        }),
        specialHoursInheritedByParent: Yup.boolean().required(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues]
  );

  const onSubmitCallback = useCallback<OnSubmitType>(
    async (values, formikHelpers) => {
      await onSubmit(values, formikHelpers);
      setIsEditingFalse();
    },
    [setIsEditingFalse, onSubmit]
  );

  return (
    <Formik<OpeningHoursSpecial>
      initialValues={initialValues}
      onSubmit={onSubmitCallback}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => {
        const { futureDays, pastDays } = groupSpecialHours(values);

        return (
          <Form data-testid="form-special">
            <BoxContent
              title={
                <Typography.Text strong>
                  <FormattedMessage id="opening-hours.special.upcoming" />
                </Typography.Text>
              }
              topRightContent={
                <FormControlButtons
                  name="specialHoursInheritedByParent"
                  isEditingEnabled={isEditingEnabled}
                  onIsEditingToggleRequest={setIsEditing}
                  onInheritFromParent={mode === MODE.CARE_UNIT ? setIsEditingFalse : undefined}
                />
              }
              topContent={<InheritanceStateInfo mode={mode} isSpecialHours />}
            >
              <FieldArray
                name="detailedSpecialHourPeriods"
                render={arrayHelpers => (
                  <>
                    {!futureDays && (
                      <p>
                        <Typography.Text type="secondary">
                          <FormattedMessage id="opening-hours.special.no-upcoming" />
                        </Typography.Text>
                      </p>
                    )}
                    {(isEditingEnabled || !futureDays) && (
                      <Button
                        className={style.addSpecialButton}
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={async () => {
                          setIsEditingTrue();
                          await setFieldValue('specialHoursInheritedByParent', false);
                          arrayHelpers.push({
                            startDate: null,
                            endDate: null,
                            openTime: null,
                            closeTime: null,
                            closedAllDay: true,
                          });
                        }}
                      >
                        <FormattedMessage id="opening-hours.special.add" />
                      </Button>
                    )}
                    {futureDays && (
                      <table>
                        <tbody>
                          {futureDays.map((day, index) => (
                            <RowSpecialDay
                              key={`${index}-${day.startDate}-${day.endDate}`}
                              name={`detailedSpecialHourPeriods[${values.detailedSpecialHourPeriods.indexOf(
                                day
                              )}]`}
                              disabled={!isEditingEnabled}
                              onDelete={
                                isEditingEnabled
                                  ? () =>
                                      arrayHelpers.remove(
                                        values.detailedSpecialHourPeriods.indexOf(day)
                                      )
                                  : null
                              }
                            />
                          ))}
                        </tbody>
                      </table>
                    )}
                  </>
                )}
              />
            </BoxContent>
            <BoxContent
              title={
                <Typography.Text strong>
                  <FormattedMessage id="opening-hours.special.previous" />
                </Typography.Text>
              }
            >
              {pastDays?.length ? (
                <table>
                  <tbody>
                    {pastDays?.map((day, index) => (
                      <RowSpecialDay
                        key={`${index}-${day.startDate}-${day.endDate}`}
                        name={`detailedSpecialHourPeriods[${values.detailedSpecialHourPeriods.indexOf(
                          day
                        )}]`}
                        disabled
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                <Typography.Text type="secondary">
                  <FormattedMessage id="opening-hours.special.no-previous" />
                </Typography.Text>
              )}
            </BoxContent>
          </Form>
        );
      }}
    </Formik>
  );
};
